import logo from '../../ressource/logo.svg';
import './Home.css';
import axios from 'axios';

const apiUri = process.env.REACT_APP_KUMITE_APP_API_URI;

function Header(){
  const token = localStorage.getItem('authToken');
  const user = token ? 'Nome do usuário' : 'Login'; // Substitua 'Nome do usuário' pela lógica para obter o nome do usuário
  const link = token ? 'tela-de-usuario' : 'sing-in'; // Substitua 'tela-de-usuario' pelo link para a tela do usuário

  const logout = async () => {
    try {
      // Faça a chamada de Logout para o seu backend
      const response = await axios.post(apiUri + '/auth/logout', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response)
    } catch (error) {
      console.error('Erro ao fazer Logout:', error);
    }finally{
      localStorage.removeItem('authToken');
      window.location.reload();
    }
  };

  return (
    <div className='header-container'>
        <div className='header-div-left'>
          {/*eslint-disable-next-line*/}
          <a href="#" className='header-div-left-content' >
          </a>
        </div>

        <div>
        </div>
        <div className='header-div-rigt'>
          <a href={link} className='header-content-div-right' > {user}</a>
          {token && <button onClick={logout}>Logout</button>}
        </div>
    </div>
  );
}

function Banner(){
  return (
    <div className='banner-container' >
      <div className='banner'/>
    </div>
  );
}

function Content() {
  return (
    <div className='content-container'>
      <div className='content'>
        <img src={logo} alt="logo" />
          <p>
            Kumite-tv <code>INPROGRESS teste</code> !
          </p>
          <a
            href="https://github.com/DiogoCasagrande"
            target="_blank"
            rel="noopener noreferrer"
          >
            Diogo Casagrande
          </a>
      </div>
    </div>
  );
}



function Home(){
  console.log('TOKEN')
  console.log(localStorage.getItem('authToken'))
  return (
    <div className='container'>
      <Header/>
      <Banner/>
      <Content/>
    </div>
  );
}

export default function App(){
  return <Home/>;
}
