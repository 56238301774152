import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SingUp.css';

const apiUri = process.env.REACT_APP_KUMITE_APP_API_URI;

function Header(){
  return (
    <div className='header-container'>
      <div className='header-div-left'>
        <a href="/" className='header-div-left-content' >
        </a>
      </div>

      <div className='div-rigt' />
    </div>
  );
}

function SingUpContent() {
  const navigate = useNavigate(); // Inicialize navigate usando o hook useNavigate

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [primeiroNome, setPrimeiroNome] = useState('');
  const [sobreNome, setSobreNome] = useState('');

  const handleSingup = async () => {
    try {
      console.log(userName, password)
      // Faça a chamada de login para o seu backend
      const response = await axios.post(apiUri + '/auth/singup', { 
        userName,
        email,
        password,
        primeiroNome,
        sobreNome
      });

      console.log(response)
      // Se a chamada for bem-sucedida, redirecione para a página desejada
      if (response.status === 201) {
        console.log("Sucesso ao criar usuario")

        navigate('/'); //  '/'  caminho desejado
      } else {
        console.error('Login falhou. Mensagem do servidor:', response.data.message);
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <div className='login-container'>
      <div className='content'>
        <input type="text" placeholder="username" value={userName} onChange={(e) => setUserName(e.target.value)}/>
        <input type="text" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
        <input type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
        <input type="text" placeholder="primeiroNome" value={primeiroNome} onChange={(e) => setPrimeiroNome(e.target.value)}/>
        <input type="text" placeholder="sobreNome" value={sobreNome} onChange={(e) => setSobreNome(e.target.value)}/>

        <div className='button-container'>
          <button onClick={handleSingup}>Criar</button>
        </div>
      </div>
    </div>
  );
}



function Page(){
  return (
    <div className='container'>
      <Header/>      
      <SingUpContent/>
    </div>
  );
}

export default function SingUp(){
  return <Page/>;
}
