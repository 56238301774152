import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


import Home from './components/home/Home';
import SingIn from './components/auth/singIn/SingIn';
import SingUp from './components/auth/singup/SingUp';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/sing-in",
    element: <SingIn/>
  },  
  {
    path: "/criar-conta",
    element: <SingUp/>
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
