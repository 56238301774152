import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './SingIn.css';
const apiUri = process.env.REACT_APP_KUMITE_APP_API_URI;

function Header(){
  console.log('TELA DE SINGIN')
  return (
    <div className='header-container'>
      <div className='header-div-left'>
        <a href="/" className='header-div-left-content' >
        </a>
      </div>

      <div className='div-rigt' />
    </div>
  );
}

function LoginContent() {
  const navigate = useNavigate(); // Inicialize navigate usando o hook useNavigate

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      // Faça a chamada de login para o seu backend
      const response = await axios.post(apiUri + '/auth/singin', { username, password });

      // Se a chamada for bem-sucedida, redirecione para a página desejada
      if (response.data && response.data.access_token) {
        console.log("armazenando token e trocando de tela")

        localStorage.setItem('authToken', response.data.access_token);
        navigate('/'); //  '/'  caminho desejado
      } else {
        console.error('Login falhou. Mensagem do servidor:', response.data.message);
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <div className='login-container'>
      <div className='content'>
        <input type="text" placeholder="usuario ou email" value={username} onChange={(e) => setUsername(e.target.value)}/>
        <input type="password" placeholder="senha" value={password} onChange={(e) => setPassword(e.target.value)} />
        <div className='button-container'>
          <button onClick={handleLogin}>Login</button>
          <Link className='link-button' to="/criar-conta">Criar conta</Link>
        </div>
      </div>
    </div>
  );
}



function Page(){
  return (
    <div className='container'>
      <Header/>
      <LoginContent/>
    </div>
  );
}

export default function SingIn(){
  return <Page/>;
}
